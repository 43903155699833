import React from 'react';
import {withTranslation} from "react-i18next";
import {Col, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import can from '../img/can.svg';
import sign from '../img/sign.svg';
import badge from '../img/badge.svg';
import barrelside from '../img/barrelside.svg';
import axios from "axios";
import beerConfig from "../beerconfig.json";

function Stats({t, i18n}) {
    let [responseData, setResponseData] = React.useState('');
    let [countData, setCountData] = React.useState('');
   // let [likesData, setLikesData] = React.useState('');

    const fetchData = React.useCallback(() =>  {
        axios.get("https://ab-itteam.github.io/vandijckcontent/locations.json",{responseType: 'json'})
            .then((response) => setResponseData(response.data))
            .catch((error) => {console.log(error)})
    },[])

    const fetchCount = React.useCallback(() =>  {
        axios.get("https://untappd.brouwerijvandijck.be",{responseType: 'json'})
            .then((response) => setCountData(response.data.count))
            .catch((error) => {console.log(error)})
    },[])

    /*const fetchLikes = React.useCallback(() =>  {
        axios.get("https://untappd.brouwerijvandijck.be/likes",{responseType: 'json'})
            .then((response) => setLikesData(response.data.likes))
            .catch((error) => {console.log(error)})
    },[])*/

    React.useEffect(() => {
        fetchData()
    }, [fetchData])
    React.useEffect(() => {
        fetchCount()
    }, [fetchCount])
   /* React.useEffect(() => {
        fetchLikes()
    }, [fetchLikes])
*/
    return (
        <Container fluid={true} className={"super-dark"} style={{padding:"50px", zIndex:1,background:"rgb(33, 37, 41)" }}>
            <section style={{background: "rgb(33, 37, 41)"}}>
                <Container>
                    <Row>
                        <StatElement img={{src: can, alt: "can"}} amount={amountOfBeersCounter(beerConfig)} text={t('stats1Title')}/>
                        <StatElement img={{src: sign, alt: "sign"}} amount={LocationCounter(responseData)} text={t('stats2Title')}/>
                        <StatElement img={{src: badge, alt: "badge"}} amount={countData} text={t('stats3Title')}/>
                        {/*<StatElement img={{src: barrelside, alt: "can"}} amount={likesData} text={t('stats4Title')}/>*/}
                        <StatElement img={{src: barrelside, alt: "can"}} amount={"830"} text={t('stats4Title')}/>
                    </Row>
                </Container>
            </section>
        </Container>
    );
}

const amountOfBeersCounter = (beerConfig) => {
    return beerConfig.reduce((acc, b) => {
       acc += b.count ? b.count : 1;
       return acc;
    },0)
}

const StatElement = (props) => {
    return<Col xs={6} sm={3}>
        <div className="counter">
            <img src={props.img.src} alt={props.img.alt}/>
            <span>{props.amount}</span>
            {props.text}
        </div>
    </Col>
};

const LocationCounter = (locations) => {
const amount = Object.keys(locations).reduce((acc,x) => {
    acc = acc + Object.keys(locations[x]).reduce((acc1,y) => {
        const country = locations[x];
        const province = country[y];
        acc1 = acc1 + province.length;
        return acc1;
    },0)
    return acc;
},0)
    return `${amount}`
}
export default withTranslation()(Stats);
