import React from 'react';
import {withTranslation} from "react-i18next";
import Hero from "../components/Hero";
import hops from '../img/bannerIcons/hops.svg'
import mill from '../img/bannerIcons/mill.svg'
import sixpack from '../img/bannerIcons/sixpack.svg'
import pint from '../img/bannerIcons/pint.svg'
import {Link} from "react-router-dom";
import Stats from "../components/Stats";
import Wrapper from "../components/Wrapper";
import ScrollToTop from "../components/ScrollToTop";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useEffect } from 'react';
import Helmet from "../components/Helmet";
import BeerRow from "../components/BeerRow";
import ScrollButton from "../components/ScrollButton";
import beerConfig from "../beerconfig.json";

const IconCol = ({img,content}) => {
    return <Col sm={3} xs={6} className={"icon-grid"} style={{height: "197px"}}>
            <img src={img.src} alt={img.alt}/>
            <h4>{content}</h4>
        </Col>
};


function OurBeer({t,i18n}) {
    useScrollToHash();
    return (
       <Wrapper>
           <Helmet title={`${t('ourBeerHero1')} ${t('ourBeerHero2')}`} description={t('Slijkvisser')}/>
           <Hero title1={t('ourBeerHero1')} title2={t('ourBeerHero2')}/>
            <Container fluid={true} style={{zIndex:1,background:"rgb(33, 37, 41)",padding: 0,margin: 0 }}>
                <section  style={{background: "rgb(33, 37, 41)",padding: 0}}>
                    <Row>
                    <IconCol img={{src:hops,alt:"hops"}} content={t('ourBeerIcon1')} />
                    <IconCol img={{src:mill,alt:"mill"}} content={t('ourBeerIcon2')} />
                    <IconCol img={{src:sixpack,alt:"sixpack"}} content={t('ourBeerIcon3')} />
                    <IconCol img={{src:pint,alt:"pint"}} content={t('ourBeerIcon4')} />
                    </Row>
                </section>
            </Container>
           <Container fluid={true} style={{zIndex:1,background:"rgb(33, 37, 41)",padding: 0,margin: 0 }}>
                <Row>
                    <Container>
                        {beerConfig.map((x,i) =>  <BeerRow t={t}
                                                           img={x.bottleAndGlassImg}
                                                           anchor={x.beerAnchor}
                                                           name={x.name ? x.name : x.beerName}
                                                           subName={x.series}
                                                           count={x.translationId}/>)}
                    </Container>
                </Row>
            </Container>
            <Stats/>
            <ScrollToTop/>
           <ScrollButton/>
       </Wrapper>
    );
}



function useScrollToHash() {
    useEffect(() => {
        const { hash } = window.location;
        if (hash) {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            if (element) element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }, []);
}





export default withTranslation()(OurBeer);
