import React from 'react';
import i18n from "i18next";
import logo from '../img/logo.png';
import FadeIn from 'react-fade-in';
import {Card} from "react-bootstrap";
import Helmet from "../components/Helmet";
import Wrapper from "../components/Wrapper";

class LanguageChooser extends React.Component {

    constructor() {
        super();
        this.state = {language: localStorage.getItem('lng')};
    }

    async setLanguage(lng) {
        localStorage.setItem('lng', lng);
        await i18n.changeLanguage(lng);
        this.setState({language: lng});
    }



    render() {
        return !this.state.language ?
            <div>
                <Helmet title={`Brouwerij Vandijck`} description={'Brouwerij Vandijck'}/>
                <Card style={{background:"none",color:"white"}} className={"text-center"}>
                <FadeIn transitionDuration = "5000" >
                    <Card.Header>
                        <img className="card-img-top" src={logo} alt="brouwerij logo" style={{width:"70vh"}}/>
                    </Card.Header>
                </FadeIn>
                <Card.Body>
                    <Card.Title>
                        Taalkeuze / Language
                    </Card.Title>
                    <Card.Text>
                        Kies uw taal / Choose your language
                        <p style={{padding:"10px"}}>
                        <button type="button" style={btnStyle} onClick={() => this.setLanguage("nl")}>NL</button>
                        <button type="button" style={btnStyle} onClick={() => this.setLanguage('en')}>EN</button>
                        </p>
                    </Card.Text>
                </Card.Body>
            </Card>
            </div>
            : this.props.children
    }

}

const btnStyle ={
    fontSize: "12px",
fontWeight: 800,
position: "relative",
display: "inline-block",
margin: "0 0 10px",
padding: "15px 30px",
transition: "all .3s ease-in-out",
letterSpacing: "5px",
textTransform: "uppercase",
border: "none",
borderWidth: "2px",
borderRadius: 0,
boxShadow: "inset 0 0 0 2px #131314",
};

export default LanguageChooser;
