import React from 'react';
import brewery2023 from '../img/brewery/Brewery2023.jpeg'
import block2 from '../img/block-bg-2.jpg'
import {withTranslation} from "react-i18next";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";

function AboutBrewery({withButton, t, i18n}) {
    return (
        <Container fluid={true} style={{padding:0}}>
            <section className={"light section"} style={{backgroundImage: `url(${block2})`,backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                <Container>
                    <Row>
                        <Col sm={6} className={"matchHeight"} style={{height: 442,  position: "relative"}}>
                                <img src={brewery2023} alt="About our Brewery" className="alignMiddle" style={{
                                    border: "10px solid #fff",
                                    borderBottom: "45px solid #fff",
                                    boxShadow: "3px 3px 3px #777"}}/>

                        </Col>
                        <Col sm={5} className={"col-sm-push-1 matchHeight"} style={{height: 442}}>
                            <section className="alignMiddle mobile-center" style={{background: "none"}}>
                                <header>
                                    <h1>{t('aboutBreweryTitle1')}</h1>
                                    <h2 style={{color:"#000"}}>{t('aboutBreweryTitle2')}</h2>
                                </header>
                                <p>{t('aboutBreweryContent')}</p>
                                {button(withButton, t('generalBtnLearnMore'))}
                            </section>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Container>
    );
}

const button = (showButton, text) => {
    return showButton ? <Link to="/story" className="btn btn-default"><span>{text}</span></Link> : '';
}

export default withTranslation()(AboutBrewery);
