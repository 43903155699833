import React from "react";
import logo from '../img/logo.png'
import {withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import './StickyNavBar.css'
import NavItem from "react-bootstrap/NavItem";
import Col from "react-bootstrap/Col";

import Socials from "./Socials";
import {Row} from "react-bootstrap";




function StickyNavBar({t, i18n}) {
    const changeLanguage = (lng) => {
        localStorage.setItem('lng', lng);
        i18n.changeLanguage(lng);
    };

    return (<Navbar expand="lg" fixed="top" style={{backgroundColor: "rgba(24, 25, 26, .9)", padding: 20}}>
        <Navbar.Brand href="/" style={{marginLeft: "25%"}} >
            <img
                src={logo}
                width="80"
                height="80"
                className="d-inline-block align-top"
                alt="logo"
            />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{background : "#cbb27c"}}/>
        <Navbar.Collapse id="basic-navbar-nav" style={{top: "50%"}}>
            <Nav className="mr-auto navbar-nav" style={navStyle}>
                <NavMenuItem to={"/"} text={t('navItem1')}/>
                <NavMenuItem to={"/beers"} text={t('navItem2')}/>
                <NavMenuItem to={"/story"} text={t('navItem3')}/>
                <NavMenuItem to={"/contact"} text={t('navItem4')}/>
                <NavMenuItem to={"/find"} text={t('navItem5')}/>

            </Nav>
            <Socials/>
            <Col>
                <Row style={{marginTop: "2%"}}>
                    <button style={{background:"none",border:"none",color: "white",padding:"10px"}} onClick={() => changeLanguage("nl")}>NL</button>
                    <button style={{background:"none",border:"none",color: "white",padding:"10px"}} onClick={() => changeLanguage("en")}>EN</button>
                </Row>
            </Col>
        </Navbar.Collapse>
    </Navbar>);
}





const NavMenuItem = ({to, text}) => {
    return <Col>

        <NavItem style={{float: "left", whiteSpace: "nowrap"}}>

            <NavLink to={to} style={navLinkStyle}>
                {text}
            </NavLink>

        </NavItem>

    </Col>
};

const navStyle = {
    "position": "relative",
    "top": "15%",
    "right": 0,
    "float": "none",
};

const navLinkStyle = {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: ".8em",
    display: "inline-block",
    padding: "14px 10px 12px",
    letterSpacing: "3px",
    textTransform: "uppercase",
    color: "#fff"
};

export default withTranslation()(StickyNavBar);
