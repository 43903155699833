import React from 'react';
import blockbg from '../img/block-bg-3.jpg'
import {withTranslation} from 'react-i18next';
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import beerConfig from "../beerconfig.json";

function Beers({t, i18n}) {
    return (
        <Container fluid={true} >
            <section className="container-fluid" style={{backgroundImage: `url(${blockbg})`,color: "#fff"}}>
                <Container>
                    <Row>
                        <Col sm={5} className={"matchHeight scrollme animateme"} data-when="enter" data-from="0.75"
                             data-to="0"
                             data-opacity="0" data-translatex="-75" style={{height: 351}}>
                            <div className="alignMiddle mobile-center">
                                <header>
                                    <h1>{t('beerTitle1')}</h1>
                                    <h2>{t('beerTitle2')}</h2>
                                </header>
                                <p>{t('beerContent')}</p>
                                <Link  to="/beers" className="btn btn-default"><span>{t('generalBtnMore')}</span></Link>
                            </div>
                        </Col>
                        <Col sm={6} className={"col-sm-push-1 matchHeight"}>
                            <Carousel indicators={false} style={{zIndex:1 }}>
                                {beerConfig.map(x =>  <BeerCarouselItem title={t(`${x.beerName}`)}
                                                                        beerOverlay ={t('beerOverlay')}
                                                                        img ={x.bottleImg} />)}
                            </Carousel>

                        </Col>
                    </Row>
                </Container>
            </section>
        </Container>
);
}

function BeerCarouselItem(props) {
    return (
        <Carousel.Item {...props}>
        <div>
            <div style={{ margin:"auto"}}>
                <div className="product item">
                    <Link to="/beers">
                        <span>{props.beerOverlay}</span>
                        <img src={`${process.env.PUBLIC_URL}/beerimg/bottles/${props.img}`} alt="Beer bottle" style={{transition: "all 300ms ease-in-out", maxHeight: "350px"}}/>
                    </Link>
                    <h3>{props.title.indexOf(' ')>= 1 ? <div>{props.title.split(" ").map((x,i) => <span> {i ? <br/> : ""}{x}</span>)}</div> : <div><br/> {props.title}</div>}</h3>
                </div>
            </div>
        </div>
    </Carousel.Item>
    )
}



export default withTranslation()(Beers);
