import React,{Suspense} from 'react';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import Hero from "../components/Hero";
import Wrapper from "../components/Wrapper";
import {Accordion, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import {withTranslation} from "react-i18next";
import ScrollToTop from "../components/ScrollToTop";
import ScrollButton from "../components/ScrollButton";
import axios, {all} from "axios";
import Helmet from "../components/Helmet";


const mapStyles = {
    width: '100%',
    height: '100%',
    position: "absolute"
};
function Find(props) {
    const {t} = props;

    let [responseData, setResponseData] = React.useState('');
    let [activeKey, setActiveKey] = React.useState(1);

    const fetchData = React.useCallback(() =>  {
        axios.get("https://ab-itteam.github.io/vandijckcontent/locations.json",{responseType: 'json'})
            .then((response) => setResponseData(response.data))
            .catch((error) => {console.log(error)})
    },[])

    React.useEffect(() => {
        fetchData()
    }, [fetchData])

    const onMarkerclick = (props, marker, e) => {
        console.log(props);
        setActiveKey(props.activeKey);
        console.log(marker);
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
        <Wrapper>
            <Helmet title={`${t('findHero2')}`} description={`${t('findHero1')} ${t('findHero2')}`}/>
            <ScrollToTop/>
            <Hero title1={t('findHero1')} title2={t('findHero2')}/>
            {responseData ?
            <Container fluid={true} style={{margin: 0, padding: 0}}>
                <section style={{padding: 0}}>
                    <Row>
                        <Col sm={6} className={"matchHeight"}>
                            <Map
                                google={props.google}
                                zoom={10}
                                style={mapStyles}
                                initialCenter={getCenter(responseData)}
                                center={getCenter(responseData)}
                                centerAroundCurrentLocation={false}
                            >
                                {allLatLng(responseData).map((loc, index) => <Marker key={index}
                                                                                 position={{
                                                                                     lat: loc.lat,
                                                                                     lng: loc.long
                                                                                 }}
                                                                                     locationData = {loc}
                                                                                     activeKey ={index+1}
                                                                                 onClick={onMarkerclick}/>)}
                            </Map>

                        </Col>
                        <LocationGenerator locations={responseData} activeKey={activeKey} setActiveKey = {setActiveKey}/>
                    </Row>
                </section>
            </Container>
                : ""}
            <ScrollButton/>
        </Wrapper>
        </Suspense>
    );
}

const getCenter = (locations) => {
    console.log(locations);
    const allLocationsMarkers = allLatLng(locations);
    console.log(allLocationsMarkers);
    if(allLocationsMarkers && allLocationsMarkers[0]){
        return {
            lat: allLocationsMarkers[0].lat,
            lng: allLocationsMarkers[0].long
        }
    }

}

const allLatLng = (obj) => {
    if (obj) {
        const lvl1 = Object.values(obj).flat();
        console.log(lvl1);
        return lvl1
            .map((country) =>
                Object.values(country).flat().filter((x) => x.lat && x.long)
            )
            .reduce((acc, curr) => acc.concat(curr), []);
    }
};
const LocationGenerator = (props) => {
    const { locations, activeKey, setActiveKey } = props;
    const splitSize = 5;

    return (
        <Col sm={6} className={"matchHeight"}>
            {Object.keys(locations).map((country, countryIndex) => (
                <Accordion key={countryIndex} defaultActiveKey={`country_${countryIndex}`}>
                    <Card style={{ height: "100%" }}>
                        <Accordion.Toggle as={Card.Header} eventKey={`country_${countryIndex}`}>
                            {country}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`country_${countryIndex}`}>
                            <Card.Body>
                                {Object.keys(locations[country]).map((province, provinceIndex) => {
                                    const provinceData = locations[country][province];
                                    const provinceRepeater = provinceData.length;
                                    const splittedProvinceData = [];
                                    for (let i = 0; i < Math.ceil(provinceRepeater / splitSize); i++) {
                                        splittedProvinceData[i] = provinceData.slice(i * splitSize, i * splitSize + splitSize);
                                    }

                                    return splittedProvinceData.map((locationsChunk, chunkIndex) => (
                                        <Card key={`${country}_${province}_${chunkIndex}`} style={{
                                            margin: "10px",
                                            display: "inline-block",
                                            width: '18rem',
                                            float: "inline-start"
                                        }}>
                                            <Card.Header>{`${province}`}</Card.Header>
                                            <ListGroup variant="flush">
                                                {locationsChunk.map((location, locationIndex) => (
                                                    <ListGroup.Item key={`${country}_${province}_${chunkIndex}_${locationIndex}`}>
                                                        <div id={"test"}>
                                                            <Card style={{
                                                                display: "inline-block",
                                                                float: "inline-start"
                                                            }}>
                                                                <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                                                                    <Accordion.Toggle as={Card.Header} eventKey={`${country}_${province}_${chunkIndex}_${locationIndex + 1}`}>
                                                                        {location.name}
                                                                    </Accordion.Toggle>
                                                                    <Accordion.Collapse eventKey={`${country}_${province}_${chunkIndex}_${locationIndex + 1}`}>
                                                                        <Card.Body>
                                                                            <p>{location.address}</p>
                                                                            <a target="_blank" rel="noopener noreferrer" href={location.url}>
                                                                                {location.url}
                                                                            </a>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                </Accordion>
                                                            </Card>
                                                        </div>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Card>
                                    ));
                                })}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            ))}
        </Col>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBd7aW06kK_RFMIPuTBcyS38f8O660cZVQ'
})(withTranslation()(Find));
