import Col from "react-bootstrap/Col";
import {Row} from "react-bootstrap";
import untappd from "../img/untappd.svg";
import instagram from "../img/instagram.svg";
import fb from "../img/fb.svg";
import React from "react";

export default function () {
   return <Col>
        <Row className="socials" style={{marginTop: "2%"}}>
            <a target="_blank rel=noopener" href="https://untappd.com/BrouwerijVandijck/beer" aria-hidden="true"><img src={untappd} alt={"untappd"} style={{marginLeft: "10px", width: "2em"}}/></a>
            <a target="_blank rel=noopener" href="https://www.instagram.com/brouwerijvandijck" aria-hidden="true"><img src={instagram} alt={"instagram"} style={{marginLeft: "10px", width: "2em"}}/></a>
            <a target="_blank rel=noopener" href="https://www.facebook.com/brouwerijvandijck" aria-hidden="true"><img src={fb} alt={"facebook"} style={{marginLeft: "10px", width: "2em"}}/></a>
        </Row>
    </Col>
}
