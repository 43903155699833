import React from 'react';
import {useScrollToTop} from "../utils";
import "./ScrollButton.css"


function ScrollButton(props) {
    const setScrollToTop = useScrollToTop(true);
    return (
            <button onClick={() => setScrollToTop(true)} id="scrollButton" title="Go to top">^</button>
    );
}


export default (ScrollButton);